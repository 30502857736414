import { useState } from "react";
import { useQuery } from "react-query";
import { eventSubscriberApiConsumer } from "apiConsumers";
import IEvent from "apiConsumers/consumers/eventSubscriber/models/IEvent";

export default function useGetEventViaShortcode(eventShortCode: string) {
    const [diableQuery, setDisableQuery] = useState(false);

    return useQuery<IEvent, Error>(
        ["getEventViaShortcode", eventShortCode],
        () => eventSubscriberApiConsumer.getEventViaShortcode(eventShortCode),
        {
            retry: false,
            onError: (error) => {
                setDisableQuery(true);
            },
            enabled: !diableQuery,
        }
    );
}
