import React, { ReactNode } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            minHeight: "100vh",
            color: theme.palette.text.primary,
        },
        toolbar: theme.mixins.toolbar,
        content: {
            flex: 1,
            display: "flex",
            justifyContent: "center",
        },
    })
);

interface IStickyFooterProps extends React.HTMLAttributes<HTMLDivElement> {
    content: ReactNode;
    footer: ReactNode;
}

export default function StickyFooter(props: IStickyFooterProps) {
    const classes = useStyles();
    const { content, footer, ...rest } = props;

    return (
        <main className={classes.root} {...rest}>
            <div className={classes.toolbar} />
            <div className={classes.content}>{content}</div>
            {footer}
        </main>
    );
}
