import { makeStyles, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { appName } from "consts";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.background.paper,
            textAlign: "center",
            padding: theme.spacing(1),
        },
    })
);

export default function Footer() {
    const classes = useStyles();

    return (
        <footer className={classes.root}>
            <Typography>
                © {new Date().getFullYear()} {appName}
            </Typography>
        </footer>
    );
}
