import React, { ReactNode } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { layout } from "consts";
import clsx from "clsx";

const useStyles = makeStyles((theme) => {
    return createStyles({
        contentWrapper: {
            flex: 1,
            display: "flex",
            justifyContent: "center",
        },
        maxWidthContainer: {
            flex: 1,
            maxWidth: layout.siteContentMaxWidth,
            padding: theme.spacing(3, 3),
            [theme.breakpoints.down("sm")]: {
                padding: theme.spacing(2, 2),
            },
            [theme.breakpoints.down("xs")]: {
                padding: theme.spacing(1, 1),
            },
        },
    });
});

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: ReactNode;
}

export default function PageSection(props: IProps) {
    const { children, className, ...rest } = props;
    const classes = useStyles();

    return (
        <section className={clsx(className, classes.contentWrapper)} {...rest}>
            <div className={classes.maxWidthContainer}>{children}</div>
        </section>
    );
}
