import { useMediaQuery, useTheme } from "@material-ui/core";
import { DatePickerProps } from "@material-ui/pickers";

export default function useDatePickerOptions(): Partial<DatePickerProps> {
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return {
        variant: smScreen ? "dialog" : "inline",
        format: "dd/MM/yyyy", // We need to internationalise dates - https://github.com/Mortifera/EventSubscriberFrontend/issues/90
        placeholder: "dd/mm/yyyy",
    };
}
