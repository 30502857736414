import React, { ReactNode } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import { defaultInputVariant } from "consts";

import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";

import StyledTextField from "components/inputs/StyledTextField";

import EmailIcon from "@material-ui/icons/Email";
import SmsIcon from "@material-ui/icons/Sms";

import { Controller, ControllerRenderProps, UseFormMethods } from "react-hook-form";
import { hasError } from "utils/formValidationUtils";
import subscriptionInputValidationValues from "./subscriptionInputValidationValues";
import SubscriptionInputFormData from "./SubscriptionInputFormData";
import SubscriptionMethodType from "models/SubscriptionMethodType";

const useStyles = makeStyles((theme) =>
    createStyles({
        subscriptionInput: {},
        subscriptionMethod: {
            marginTop: theme.spacing(1),
            display: "flex",
        },
        subscriptionTypeSelect: {
            marginRight: theme.spacing(1),
        },
        selectedItemIcon: {
            display: "flex",
            alignItems: "center",
            fontSize: "1.2rem",
        },
        listItemIcon: {
            fontSize: "1.5rem",
        },
        inputContainer: {
            flex: 1,
        },
    })
);

interface ISubscriptionMethod {
    icon: ReactNode;
    label: string;
    type: SubscriptionMethodType;
    renderInput: ({
        ref,
        value,
        onChange,
    }: ControllerRenderProps<Record<string, any>>) => JSX.Element;
}

interface IProps {
    form: UseFormMethods<SubscriptionInputFormData>;
    disabled?: boolean;
}

export default function SubscriptionInput(props: IProps) {
    const { disabled = false, form } = props;
    const { control, errors, watch } = form;

    const classes = useStyles();

    const subscriptionMethods: ISubscriptionMethod[] = [
        {
            icon: <EmailIcon fontSize="inherit" />,
            label: "Email",
            type: "Email",
            renderInput: ({ ref, value, onChange }) => (
                <StyledTextField
                    label="Email address"
                    inputRef={ref}
                    value={value}
                    onChange={onChange}
                    error={hasError(errors.subscriptionInputValue?.message)}
                    helperText={errors.subscriptionInputValue?.message || " "}
                    disabled={disabled}
                    type="email"
                    fullWidth
                    inputProps={{
                        maxLength: subscriptionInputValidationValues.phoneNumber.maxLength,
                    }}
                />
            ),
        },
        {
            icon: <SmsIcon fontSize="inherit" />,
            label: "SMS",
            type: "SMS",
            renderInput: ({ ref, value, onChange }) => (
                <StyledTextField
                    label="Phone number"
                    inputRef={ref}
                    value={value}
                    onChange={onChange}
                    error={hasError(errors.subscriptionInputValue?.message)}
                    helperText={errors.subscriptionInputValue?.message || " "}
                    disabled={disabled}
                    fullWidth
                    inputProps={{
                        maxLength: subscriptionInputValidationValues.phoneNumber.maxLength,
                    }}
                />
            ),
        },
    ];

    const defaultSubscriptionMethod = subscriptionMethods[0];
    const seletedSubscriptionMethodType = watch(
        "subscriptionMethodType",
        defaultSubscriptionMethod.type
    );

    const getSubscriptionMethod = (methodType: SubscriptionMethodType) => {
        const selectedMethod = subscriptionMethods.find((method) => method.type === methodType);

        if (selectedMethod === undefined) return defaultSubscriptionMethod;

        return selectedMethod;
    };

    return (
        <div className={classes.subscriptionInput}>
            <Controller
                name="name"
                control={control}
                defaultValue=""
                render={({ ref, value, onChange }) => (
                    <StyledTextField
                        label="Name"
                        inputRef={ref}
                        value={value}
                        onChange={onChange}
                        error={hasError(errors.name?.message)}
                        helperText={errors.name?.message || " "}
                        disabled={disabled}
                        autoFocus
                        fullWidth
                        inputProps={{
                            maxLength: subscriptionInputValidationValues.name.maxLength,
                        }}
                    />
                )}
            />
            <div className={classes.subscriptionMethod}>
                <Controller
                    name="subscriptionMethodType"
                    control={control}
                    defaultValue={defaultSubscriptionMethod.type}
                    render={({ ref, value, onChange }) => (
                        <FormControl
                            variant={defaultInputVariant}
                            className={classes.subscriptionTypeSelect}
                        >
                            <Select
                                inputRef={ref}
                                value={value}
                                onChange={onChange}
                                renderValue={(value) => {
                                    const methodType = value as SubscriptionMethodType;

                                    const method = subscriptionMethods.find(
                                        (method) => method.type === methodType
                                    );
                                    if (method === undefined) return "";

                                    return (
                                        <span className={classes.selectedItemIcon}>
                                            {method.icon}
                                        </span>
                                    );
                                }}
                                disabled={disabled}
                            >
                                {subscriptionMethods.map((method, index) => (
                                    <MenuItem value={method.type} key={index}>
                                        <ListItemIcon className={classes.listItemIcon}>
                                            {method.icon}
                                        </ListItemIcon>
                                        <Typography variant="inherit">{method.label}</Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                />

                <div className={classes.inputContainer}>
                    <Controller
                        name="subscriptionInputValue"
                        control={control}
                        defaultValue=""
                        render={getSubscriptionMethod(seletedSubscriptionMethodType).renderInput}
                    />
                </div>
            </div>
        </div>
    );
}
