import React, { KeyboardEvent, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import routes from "routes";

import Typography from "@material-ui/core/Typography";

import PageSection from "components/layout/PageSection";
import CreateEventButton from "components/buttons/CreateEventButton";
import StyledTextField from "components/inputs/StyledTextField";
import RouterLinkButton from "components/buttons/RouterLinkButton";

const useStyles = makeStyles((theme) =>
    createStyles({
        buttonContainer: {
            marginTop: theme.spacing(2),
        },
        viewEventContainer: {
            display: "flex",
            alignItems: "center",
            "& .MuiTextField-root": {
                width: "35ch",
            },
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2),
        },
        viewEventTextField: {
            marginRight: theme.spacing(1),
        },
    })
);

export default function LandingPage() {
    const classes = useStyles();
    const history = useHistory();

    const [eventShortcode, setEventShortcode] = useState<string>("");
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEventShortcode(event.target.value);
    };

    const eventRoute = `${routes.event}/${eventShortcode}`;

    const handleViewEventKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
        const key = event.key;

        if (key.toLowerCase() === "enter") {
            history.push(eventRoute);
        }
    };

    return (
        <PageSection>
            <Typography variant="h1">Title</Typography>
            <Typography variant="h4" gutterBottom>
                Product strapline Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </Typography>
            <div className={classes.viewEventContainer}>
                <StyledTextField
                    autoFocus
                    placeholder="JuvenileDependentMantaray"
                    className={classes.viewEventTextField}
                    value={eventShortcode}
                    onChange={handleChange}
                    onKeyPress={handleViewEventKeyPress}
                />
                <RouterLinkButton color="primary" to={eventRoute} size="large">
                    View event
                </RouterLinkButton>
            </div>
            <Typography variant="h6">Or</Typography>
            <div className={classes.buttonContainer}>
                <CreateEventButton size="large">Create a new event</CreateEventButton>
            </div>
        </PageSection>
    );
}
