import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PageSection from "components/layout/PageSection";

interface IProps {
    errorCode: number;
    title: string;
    text?: string;
}

export default function ErrorPage(props: IProps) {
    const { errorCode, title, text } = props;

    return (
        <PageSection>
            <Grid container direction="column" alignItems="center">
                <Grid item>
                    <Typography variant="h1">{errorCode}</Typography>
                </Grid>

                <Grid item>
                    <Typography variant="h5" align="center">
                        {title}
                    </Typography>
                </Grid>

                {text === undefined ? null : (
                    <Grid item>
                        <Typography variant="body1" align="center">
                            {text}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </PageSection>
    );
}
