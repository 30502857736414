import React, { createContext, useContext, ReactNode, useState } from "react";
import {
    MuiThemeProvider,
    createMuiTheme,
    responsiveFontSizes,
    Theme,
} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

interface IProps {
    children: ReactNode;
}

interface IThemeContext {
    dark: boolean;
    toggle: () => void;
}

const defaultThemeContext: IThemeContext = {
    dark: false,
    toggle: () => {},
};

const ThemeContext = createContext<IThemeContext>(defaultThemeContext);

export function ThemeProvider(props: IProps) {
    const { children } = props;

    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)", {
        noSsr: true, // https://github.com/mui-org/material-ui/issues/21142 - https://github.com/mui-org/material-ui/issues/21048
    });
    const [darkState, setDarkState] = useState<boolean>(prefersDarkMode);

    const palletType = darkState ? "dark" : "light";

    const theme: Theme = responsiveFontSizes(
        createMuiTheme({
            palette: {
                type: palletType,
            },
        })
    );

    const toggleDarkTheme = () => {
        setDarkState(!darkState);
    };

    return (
        <ThemeContext.Provider
            value={{
                dark: darkState,
                toggle: toggleDarkTheme,
            }}
        >
            <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
        </ThemeContext.Provider>
    );
}

export const useThemeContext = () => useContext(ThemeContext);
