import React from "react";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardDatePickerProps,
} from "@material-ui/pickers";
import useDatePickerOptions from "./useDatePickerOptions";
import StyledTextField from "components/inputs/StyledTextField";
import RequiredTextField from "./common/RequiredTextField";

interface IProps extends KeyboardDatePickerProps {
    required?: boolean;
}

export default function StyledDatePicker(props: IProps) {
    const { required = false, ...rest } = props;

    const options = useDatePickerOptions();

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                TextFieldComponent={required ? RequiredTextField : StyledTextField}
                {...options}
                {...rest}
            />
        </MuiPickersUtilsProvider>
    );
}
