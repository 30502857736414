import React from "react";
import routes from "routes";
import RouterLinkButton, { IRouterLinkButtonProps } from "./RouterLinkButton";

type IProps = Omit<IRouterLinkButtonProps, "to">;

export default function CreateEventButton(props: IProps) {
    const { color = "secondary", size = "medium", children = "Create Event" } = props;

    return (
        <RouterLinkButton to={routes.newEvent.root} color={color} size={size} {...props}>
            {children}
        </RouterLinkButton>
    );
}
