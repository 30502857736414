import React, { Fragment } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
    createStyles({
        "@global": {
            "::selection": {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.primary.dark,
            },
        },
    })
);

interface IProps extends React.HTMLAttributes<HTMLDivElement> {}

export default function GlobalCss(props: IProps) {
    useStyles();

    return <Fragment {...props} />;
}
