import React, { ReactNode } from "react";
import StyledButton, { IStyledButtonProps } from "./StyledButton";
import { Link } from "react-router-dom";

export interface IRouterLinkButtonProps extends IStyledButtonProps {
    children?: ReactNode;
    to: string;
}

export default function RouterLinkButton(props: IRouterLinkButtonProps) {
    const { children, to, ...rest } = props;

    return (
        <StyledButton
            //@ts-ignore
            component={Link}
            to={to}
            {...rest}
        >
            {children}
        </StyledButton>
    );
}
