import React, { ReactElement } from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useThemeContext } from "contexts/ThemeContext";
import { useIsFetching } from "react-query";
import { appName } from "consts";
import routes from "routes";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import LinearProgress from "@material-ui/core/LinearProgress";

import Brightness3Icon from "@material-ui/icons/Brightness3";
import WbSunnyIcon from "@material-ui/icons/WbSunny";

import StickyFooter from "components/layout/StickyFooter";
import Footer from "components/Footer";
import RouterLink from "components/links/RouterLink";
import CreateEventButton from "components/buttons/CreateEventButton";
import logo from "assets/Logo.png";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: "flex",
        },
        appBar: {
            // zIndex: theme.zIndex.drawer + 1,
        },
        homeLogoRouterLink: {
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            "&:hover": {
                opacity: 0.8,
            },
        },
        logo: {
            maxWidth: 32,
        },
        title: {
            marginLeft: theme.spacing(2),
        },
        pageContent: {
            backgroundColor: theme.palette.background.default,
            display: "flex",
            flex: "1",
        },
        createEventButton: {
            marginRight: theme.spacing(2),
        },
    })
);

interface IProps {
    title?: string;
    children?: ReactElement;
}

export default function PageLayout(props: IProps) {
    const { title = appName, children } = props;

    const { dark: isDarkTheme, toggle: onDarkThemeToggle } = useThemeContext();
    const isFetching = useIsFetching();

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <RouterLink to={routes.landing} className={classes.homeLogoRouterLink}>
                                <img className={classes.logo} src={logo} alt={`${appName} logo`} />
                            </RouterLink>

                            <Typography variant="h6" noWrap className={classes.title}>
                                {title}
                            </Typography>
                        </div>
                        <div>
                            <CreateEventButton className={classes.createEventButton} />
                            <Tooltip title="toggle dark mode">
                                <IconButton
                                    color="inherit"
                                    aria-label="toggle dark mode"
                                    onClick={onDarkThemeToggle}
                                >
                                    {isDarkTheme ? <WbSunnyIcon /> : <Brightness3Icon />}
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                </Toolbar>
                {isFetching > 0 && <LinearProgress color="secondary" />}
            </AppBar>
            <StickyFooter
                content={<div className={classes.pageContent}>{children}</div>}
                footer={<Footer />}
            />
        </div>
    );
}
