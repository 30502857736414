import React from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";

import PageSection from "components/layout/PageSection";
import Typography from "@material-ui/core/Typography";

import IPostEventResponse from "apiConsumers/consumers/eventSubscriber/models/response/IPostEventResponse";
import RouterLinkButton from "components/buttons/RouterLinkButton";
import CopyToClipboard from "components/CopyToClipboard";
import routes from "routes";

const useStyles = makeStyles((theme) => {
    return createStyles({
        successfulEventCreationPage: {
            flex: 1,
        },
        copyToClipboard: {
            maxWidth: "45ch",
            width: "100%",
        },
    });
});

interface IProps {
    successfulEventResponse: IPostEventResponse;
}

export default function SuccessfulEventCreationPage(props: IProps) {
    const { successfulEventResponse } = props;
    const classes = useStyles();

    const origin = window.location.origin;

    const getEventRouteFromShortcode = (shortcode: string) => {
        return `${routes.event}/${shortcode}`;
    };

    const getLinkFromShortcode = (shortcode: string) => {
        const eventRoute = getEventRouteFromShortcode(shortcode);

        return `${origin}${eventRoute}`;
    };

    const publicLink = getLinkFromShortcode(successfulEventResponse.shortcode);
    const organiserLink = getLinkFromShortcode(successfulEventResponse.organiserShortcode);

    return (
        <div className={classes.successfulEventCreationPage}>
            <PageSection>
                <Typography variant="h2" gutterBottom>
                    Event created
                </Typography>

                <Typography gutterBottom>
                    Your event has been successfully created! Below are your public and organiser
                    links.
                </Typography>
                <Typography>
                    These links will be sent to your chosen subscription method.
                </Typography>
            </PageSection>

            <PageSection>
                <Typography variant="h4">Public link</Typography>
                <Typography gutterBottom>
                    Share this with people you would like to invite.
                </Typography>
                <CopyToClipboard text={publicLink} className={classes.copyToClipboard} />
            </PageSection>

            <PageSection>
                <Typography variant="h4">Organiser link</Typography>
                <Typography gutterBottom>
                    Only share this with people you want to allow to edit the event.
                </Typography>
                <CopyToClipboard text={organiserLink} className={classes.copyToClipboard} />
            </PageSection>

            <PageSection>
                <RouterLinkButton
                    to={getEventRouteFromShortcode(successfulEventResponse.organiserShortcode)}
                    color="secondary"
                >
                    View event
                </RouterLinkButton>
            </PageSection>
        </div>
    );
}
