import { defaultInputValidationValues } from "consts";

const newEventValidationValues = {
    title: defaultInputValidationValues.text,
    description: defaultInputValidationValues.text,
    coordinate: {
        step: "0.000001", // 6 decimal places gives an acuracy of ~10cm - https://gis.stackexchange.com/questions/8650/measuring-accuracy-of-latitude-and-longitude
        latitude: {
            min: -90,
            max: 90,
        },
        longitude: {
            min: -180,
            max: 180,
        },
    },
    organiserName: defaultInputValidationValues.text,
    subscriptionPhoneNumber: defaultInputValidationValues.phoneNumber,
};

export default newEventValidationValues;
