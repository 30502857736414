type QueryParameterType = string | number | boolean;

type QueryParametersObject = {
    [key: string]: QueryParameterType | undefined;
};

export default class QueryParameters {
    private readonly __parameters: URLSearchParams;

    constructor(parameters: QueryParametersObject) {
        const recordParameters = this.__convertParametersToRecord(parameters);

        this.__parameters = new URLSearchParams(recordParameters);
    }

    public toString(): string {
        return this.__parameters.toString();
    }

    private __convertParametersToRecord(parameters: QueryParametersObject): Record<string, string> {
        let record: Record<string, string> = {};

        for (const [key, value] of Object.entries(parameters)) {
            if (typeof value === "string" && value.length > 0) {
                record[key] = value;
            } else if (typeof value === "number") {
                record[key] = value.toString();
            } else if (typeof value === "boolean") {
                record[key] = value.toString();
            }
        }

        return record;
    }
}
