import IPostEventData from "apiConsumers/consumers/eventSubscriber/models/postData/IPostEventData";
import NewEventFormData from "./NewEventFormData";

export default function convertNewEventFormData(formData: NewEventFormData): IPostEventData {
    const {
        title,
        description,
        startTime,
        latitude,
        longitude,
        organiserName,
        subscriptionPhoneNumber,
    } = formData;

    return {
        title: title,
        description: description,
        location: {
            type: "GeoCoord",
            latitude: latitude,
            longitude: longitude,
        },
        dateTime: startTime,

        organiser: {
            name: organiserName,
            subscriptionMethod: {
                type: "SMS",
                value: subscriptionPhoneNumber,
            },
        },
    };
}
