import React, { ReactNode, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import StarIcon from "@material-ui/icons/Star";

import StyledButton from "components/buttons/StyledButton";
import RsvpDialog from "./RsvpDialog";
import SubscriptionType from "models/SubscriptionType";

const useStyles = makeStyles((theme) =>
    createStyles({
        rsvpButtons: {
            "& > *": {
                marginRight: theme.spacing(1),
            },
            "& > *:last-child": {
                marginRight: 0,
            },
        },
    })
);

interface IRsvpButton {
    id: SubscriptionType;
    name: string;
    icon: ReactNode;
}

const rsvpButtons: IRsvpButton[] = [
    {
        id: "Going",
        name: "Going",
        icon: <CheckIcon />,
    },
    {
        id: "Maybe",
        name: "Interested",
        icon: <StarIcon />,
    },
    {
        id: "NotGoing",
        name: "Not Going",
        icon: <ClearIcon />,
    },
];

interface IProps {
    loading?: boolean;
}

export default function RsvpButtons(props: IProps) {
    const { loading } = props;
    const classes = useStyles();

    const [currentlyOpenDialog, setCurrentlyOpenDialog] = useState<string | undefined>(undefined);

    return (
        <>
            <div className={classes.rsvpButtons}>
                {rsvpButtons.map((button, index) => {
                    const { id, name, icon } = button;

                    return (
                        <React.Fragment key={index}>
                            <StyledButton
                                size="small"
                                startIcon={icon}
                                onClick={() => setCurrentlyOpenDialog(id)}
                                disabled={loading}
                            >
                                {name}
                            </StyledButton>

                            <RsvpDialog
                                subscriptionType={id}
                                name={name}
                                icon={icon}
                                open={currentlyOpenDialog === id}
                                onClose={() => setCurrentlyOpenDialog(undefined)}
                            />
                        </React.Fragment>
                    );
                })}
            </div>
        </>
    );
}
