import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import StyledButton, { IStyledButtonProps } from "./StyledButton";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";

const loaderSize = 24;

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: { position: "relative" },
        buttonProgress: {
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: -loaderSize / 2,
            marginLeft: -loaderSize / 2,
        },
    });
});

interface IProps extends IStyledButtonProps {
    loading: boolean;
}

export default function LoadableButton(props: IProps) {
    const classes = useStyles();
    const { color, children, loading, disabled, className, ...rest } = props;

    return (
        <StyledButton
            className={clsx(className, classes.root)}
            disabled={loading || disabled}
            color={color}
            {...rest}
        >
            {children}
            {loading && (
                <CircularProgress
                    color={color === "secondary" ? "secondary" : "primary"}
                    size={loaderSize}
                    className={classes.buttonProgress}
                />
            )}
        </StyledButton>
    );
}
