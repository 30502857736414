export const appName = "event-subscriber-app";

export const layout = {
    siteContentMaxWidth: 1000,
};

export const defaultInputVariant = "outlined";

export const validationErrorMessages = {
    requiredField: "This is a required field",
};

export const defaultInputValidationValues = {
    email: {
        // https://tools.ietf.org/html/rfc5321#section-4.5.3
        maxLength: 256,
    },
    phoneNumber: {
        maxLength: 50,
    },
    text: {
        maxLength: 1000,
    },
};
