import React from "react";
import { useHistory } from "react-router-dom";
import LinkWrapper, { ILinkWrapperProps } from "./LinkWrapper";

interface IProps extends ILinkWrapperProps {
    to: string;
}

export default function RouterLink(props: IProps) {
    const { to, children, ...rest } = props;

    const history = useHistory();

    return (
        <LinkWrapper onClick={() => history.push(to)} {...rest}>
            {children}
        </LinkWrapper>
    );
}
