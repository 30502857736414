import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import QueryParameters from "apiConsumers/QueryParameters";
import removeSlash from "apiConsumers/utils/removeSlash";
import IHttpClient from "./IHttpClient";

interface IProps {
    baseUrl?: string;
    transformResponse?: (data: any) => any;
}

export default class AxiosHttpClient implements IHttpClient {
    private readonly __instance: AxiosInstance;

    constructor(props: IProps = {}) {
        const { baseUrl, transformResponse = (data: any) => data } = props;

        const config: AxiosRequestConfig = {
            baseURL: baseUrl === undefined ? undefined : removeSlash(baseUrl),
            transformResponse: transformResponse,
        };

        this.__instance = axios.create(config);
    }

    public async get(url: string, queryParameters?: QueryParameters) {
        const queryParameterString = queryParameters?.toString();

        const response = await this.__instance.get(
            `${url}${
                queryParameterString !== undefined && queryParameterString.length > 0
                    ? `?${queryParameterString}`
                    : ""
            }`
        );

        return response.data;
    }

    public async post(url: string, data?: any) {
        const response = await this.__instance.post(url, data);

        return response.data;
    }
}
