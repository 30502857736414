import AxiosHttpClient from "apiConsumers/AxiosHttpClient";
import IHttpClient from "apiConsumers/IHttpClient";
import QueryParameters from "apiConsumers/QueryParameters";

import IEvent from "./models/IEvent";

import IGetEventViaShortcodeResponse from "./models/response/IGetEventViaShortcodeResponse";

import IPostEventData from "./models/postData/IPostEventData";
import IPostEventResponse from "./models/response/IPostEventResponse";

import IEventSubscriptionPostData from "./models/postData/IEventSubscriptionPostData";
import IEventSubscriptionResponse from "./models/response/IEventSubscriptionResponse";

const apiBaseUrl = process.env.REACT_APP_BACKEND_API!;

interface IProps {
    baseUrl?: string;
    httpClient?: IHttpClient;
}

export default class EventSubscriberApiConsumer {
    private readonly __httpClient: IHttpClient;

    constructor(props: IProps = {}) {
        const {
            baseUrl = apiBaseUrl,
            httpClient = new AxiosHttpClient({ baseUrl: baseUrl }),
        } = props;

        this.__httpClient = httpClient;
    }

    public async getEventViaShortcode(shortCode: string): Promise<IEvent> {
        const url = "/api/v1/event";

        const response = await this.__httpClient.get(url, new QueryParameters({ shortCode }));

        const parsedResponse = JSON.parse(response) as IGetEventViaShortcodeResponse;

        const { time, ...rest } = parsedResponse;

        return {
            time: new Date(time),
            ...rest,
        };
    }

    public async postEvent(data: IPostEventData): Promise<IPostEventResponse> {
        const url = "/api/v1/event";

        const { dateTime, ...rest } = data;

        const response = await this.__httpClient.post(url, {
            dateTime: Math.round(dateTime.getTime() / 1000), // Epoch seconds
            ...rest,
        });

        return JSON.parse(response) as IPostEventResponse;
    }

    public async subscribeToEvent(
        eventId: string,
        data: IEventSubscriptionPostData
    ): Promise<IEventSubscriptionResponse> {
        const url = `/api/v1/event/${eventId}/subscriptions`;

        const response = await this.__httpClient.post(url, data);

        return JSON.parse(response) as IEventSubscriptionResponse;
    }
}
