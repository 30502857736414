import React, { ReactNode } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import IEvent from "apiConsumers/consumers/eventSubscriber/models/IEvent";

import Typography from "@material-ui/core/Typography";
import LocationDisplay from "./LocationDisplay";
import StyledSkeleton from "components/StyledSkeleton";
import RsvpButtons from "./rsvpButtons/RsvpButtons";

const useStyles = makeStyles((theme) =>
    createStyles({
        eventDisplayRoot: {},
        organiserText: { fontStyle: "italic", color: theme.palette.text.hint },
        eventContent: {
            display: "flex",
            marginTop: theme.spacing(2),
        },
        eventDescription: { flex: 2 },
        eventDetails: {
            flex: 1,
            marginLeft: theme.spacing(2),
            alignSelf: "flex-start",
            position: "sticky",
            top: +(theme.mixins.toolbar?.minHeight || 0) + theme.spacing(2),
        },
        timeDisplay: {
            marginBottom: theme.spacing(2),
        },
    })
);

interface IProps {
    event?: IEvent;
    loading?: boolean;
}

export default function EventDisplay(props: IProps) {
    const { event, loading } = props;
    const classes = useStyles();

    return (
        <div className={classes.eventDisplayRoot}>
            <Typography variant="h2">{showIfReady(props, event?.title)}</Typography>
            <Typography className={classes.organiserText}>
                {showIfReady(props, event?.organisers.join(", "), <StyledSkeleton width={150} />)}
            </Typography>

            <RsvpButtons loading={loading} />

            <div className={classes.eventContent}>
                <div className={classes.eventDescription}>
                    <Typography>
                        {showIfReady(props, event?.description, <DescriptionPlaceholder />)}
                    </Typography>
                </div>
                <div className={classes.eventDetails}>
                    <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                        {showIfReady(props, "Date and time", <StyledSkeleton width={100} />)}
                    </Typography>
                    <Typography className={classes.timeDisplay}>
                        {showIfReady(props, event?.time.toUTCString())}
                    </Typography>
                    <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                        {showIfReady(props, "Location")}
                    </Typography>
                    <LocationDisplay loading={loading} location={event?.location} />
                </div>
            </div>
        </div>
    );
}

const DescriptionPlaceholder = () => {
    return (
        <>
            <StyledSkeleton />
            <StyledSkeleton />
            <StyledSkeleton width={300} />
        </>
    );
};

function isReady({ event, loading = false }: IProps) {
    return !loading && event !== undefined;
}

function showIfReady(
    props: IProps,
    readyElement: ReactNode,
    placeholderElement: ReactNode = <StyledSkeleton />
) {
    const ready = isReady(props);

    if (ready) {
        return readyElement;
    }

    return placeholderElement;
}
