import * as yup from "yup";
import { ParseError, parsePhoneNumberWithError } from "libphonenumber-js";
import newEventValidationValues from "./newEventValidationValues";
import { validationErrorMessages } from "consts";

type PhoneNumberError = "INVALID_COUNTRY" | "NOT_A_NUMBER" | "TOO_LONG" | "TOO_SHORT";

const today = new Date();
today.setHours(0, 0, 0, 0);

const newEventValidationSchema = yup.object().shape({
    title: yup
        .string()
        .required(validationErrorMessages.requiredField)
        .max(newEventValidationValues.title.maxLength),
    description: yup
        .string()
        .required(validationErrorMessages.requiredField)
        .max(newEventValidationValues.description.maxLength),
    startTime: yup
        .date()
        .required(validationErrorMessages.requiredField)
        .min(today, "Time must be in the future"),
    latitude: yup
        .number()
        .required(validationErrorMessages.requiredField)
        .min(newEventValidationValues.coordinate.latitude.min)
        .max(newEventValidationValues.coordinate.latitude.max),
    longitude: yup
        .number()
        .required(validationErrorMessages.requiredField)
        .min(newEventValidationValues.coordinate.longitude.min)
        .max(newEventValidationValues.coordinate.longitude.max),
    organiserName: yup
        .string()
        .required(validationErrorMessages.requiredField)
        .max(newEventValidationValues.organiserName.maxLength),
    subscriptionPhoneNumber: yup
        .string()
        .required(validationErrorMessages.requiredField)
        .max(newEventValidationValues.subscriptionPhoneNumber.maxLength)
        .test("is-valid-phone-number", (value, context) => {
            if (value === undefined) return false;

            try {
                const phoneNumber = parsePhoneNumberWithError(value);

                return phoneNumber !== undefined;
            } catch (error) {
                if (error instanceof ParseError) {
                    const message = error.message as PhoneNumberError;

                    if (message === "INVALID_COUNTRY")
                        return context.createError({
                            message:
                                "Invalid country code. Please use a valid country code. For example +44",
                        });

                    if (message === "NOT_A_NUMBER")
                        return context.createError({ message: "Not a number" });

                    if (message === "TOO_LONG") return context.createError({ message: "Too long" });

                    if (message === "TOO_SHORT")
                        return context.createError({ message: "Too short" });
                }

                return context.createError({ message: "Phone number could not be parsed" });
            }
        }),
});

export default newEventValidationSchema;
