import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Button, { ButtonProps } from "@material-ui/core/Button";

interface IStylesOptions {
    uppercase: boolean;
}

const useStyles = (options: IStylesOptions) =>
    makeStyles((theme) => {
        const { uppercase } = options;

        return createStyles({
            root: {
                textTransform: uppercase ? "uppercase" : "none",
            },
        });
    });

export interface IStyledButtonProps extends ButtonProps {
    uppercase?: boolean;
}

export default function StyledButton(props: IStyledButtonProps) {
    const { className, uppercase = true, variant = "contained", ...rest } = props;

    const classes = useStyles({ uppercase })();

    return <Button className={clsx(classes.root, className)} variant={variant} {...rest} />;
}
