import React, { ReactNode, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import StyledButton from "components/buttons/StyledButton";
import SubscriptionInput from "./subscriptionInput/SubscriptionInput";
import LoadableButton from "components/buttons/LoadableButton";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import subscriptionInputValidationSchema from "./subscriptionInput/subscriptionInputValidationSchema";
import SubscriptionInputFormData from "./subscriptionInput/SubscriptionInputFormData";
import { eventSubscriberApiConsumer } from "apiConsumers";
import SubscriptionType from "models/SubscriptionType";

const useStyles = makeStyles((theme) =>
    createStyles({
        titleRoot: {
            display: "flex",
            alignItems: "center",
        },
        titleIcon: {
            marginRight: theme.spacing(1),
            display: "flex",
            alignItems: "center",
        },
    })
);

interface IProps {
    subscriptionType: SubscriptionType;
    name: string;
    icon: ReactNode;
    open?: boolean;
    onClose?: () => void;
}

export default function RsvpDialog(props: IProps) {
    const { subscriptionType, name, icon, open = false, onClose = () => {} } = props;
    const classes = useStyles();

    const form = useForm<SubscriptionInputFormData>({
        resolver: yupResolver(subscriptionInputValidationSchema),
    });

    const [submitting, setSubmitting] = useState<boolean>(false);
    const [erroredPostingResults, setErroredPostingResults] = useState<boolean>(false);

    const onSubmit = form.handleSubmit(async (data: SubscriptionInputFormData) => {
        const { name, subscriptionMethodType, subscriptionInputValue } = data;

        setSubmitting(true);

        try {
            const response = await eventSubscriberApiConsumer.subscribeToEvent("event id", {
                userName: name,
                subscriptionMethod: {
                    type: subscriptionMethodType,
                    value: subscriptionInputValue,
                },
                subscriptionType: subscriptionType,
            });

            console.log(response);

            onClose();
            setSubmitting(false);
        } catch (error) {
            console.log(error);
            setErroredPostingResults(true);
            setSubmitting(false);
        }
    });

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                disableBackdropClick={submitting}
                disableEscapeKeyDown={submitting}
            >
                <form onSubmit={onSubmit}>
                    <DialogTitle classes={{ root: classes.titleRoot }} disableTypography>
                        <span className={classes.titleIcon}>{icon}</span>
                        <Typography variant="h6">Respond as {name.toLocaleLowerCase()}</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            To respond to this event, please enter a subscription method. We will
                            send you updates related to this event.
                        </DialogContentText>
                        <SubscriptionInput disabled={submitting} form={form} />
                    </DialogContent>
                    <DialogActions>
                        <StyledButton disabled={submitting} onClick={onClose}>
                            Cancel
                        </StyledButton>
                        <LoadableButton loading={submitting} color="primary" type="submit">
                            Subscribe
                        </LoadableButton>
                    </DialogActions>
                </form>
            </Dialog>
            <Snackbar
                open={erroredPostingResults}
                autoHideDuration={10000}
                onClose={() => setErroredPostingResults(false)}
            >
                <Alert
                    elevation={6}
                    onClose={() => setErroredPostingResults(false)}
                    severity="error"
                >
                    There has been an error subscribing to the event. Please try again later.
                </Alert>
            </Snackbar>
        </>
    );
}
