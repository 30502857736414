const newEventRoot = "/new";

const routes = {
    landing: "/",
    newEvent: {
        root: newEventRoot,
        success: `${newEventRoot}/success`,
    },
    event: "/event",
};

export default routes;
