import React, { useState } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import routes from "routes";
import IPostEventResponse from "apiConsumers/consumers/eventSubscriber/models/response/IPostEventResponse";

import PageLayout from "components/layout/PageLayout";
import LandingPage from "pages/landing/LandingPage";
import NewEventFormPage from "pages/newEvent/newEventFormPage/NewEventFormPage";
import SuccessfulEventCreationPage from "pages/newEvent/SuccessfulEventCreationPage";
import EventPage from "pages/event/EventPage";
import NotFoundPage from "pages/error/NotFoundPage";

export default function App() {
    const history = useHistory();
    const [successfulEventResponse, setSuccessfulEventResponse] = useState<IPostEventResponse>();

    const onNewEventFormSuccess = (response: IPostEventResponse) => {
        setSuccessfulEventResponse(response);
        history.push(routes.newEvent.success);
    };

    const newEventSuccessContent =
        successfulEventResponse === undefined ? (
            <Redirect to={routes.newEvent.root} />
        ) : (
            <SuccessfulEventCreationPage successfulEventResponse={successfulEventResponse} />
        );

    return (
        <PageLayout>
            <Switch>
                <Route exact path={routes.landing} render={() => <LandingPage />} />

                <Route
                    exact
                    path={routes.newEvent.root}
                    render={() => <NewEventFormPage onSuccess={onNewEventFormSuccess} />}
                />

                <Route exact path={routes.newEvent.success} render={() => newEventSuccessContent} />

                <Route path={`${routes.event}/:eventShortCode`} render={() => <EventPage />} />

                <Route render={() => <NotFoundPage />} />
            </Switch>
        </PageLayout>
    );
}
