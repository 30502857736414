import React from "react";
import Link, { LinkProps } from "@material-ui/core/Link";

export type ILinkWrapperProps = LinkProps;

export default function LinkWrapper(props: ILinkWrapperProps) {
    const { children, ...rest } = props;

    return (
        <Link style={{ cursor: "pointer" }} {...rest}>
            {children}
        </Link>
    );
}
