import React from "react";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { defaultInputVariant } from "consts";

export type IStyledTextFieldProps = TextFieldProps;

export default function StyledTextField(props: IStyledTextFieldProps) {
    const { variant = defaultInputVariant, ...rest } = props;

    return <TextField variant={variant} {...rest} />;
}
