import * as yup from "yup";
import { ParseError, parsePhoneNumberWithError } from "libphonenumber-js";
import { validationErrorMessages } from "consts";
import subscriptionInputValidationValues from "./subscriptionInputValidationValues";

const subscriptionEmailAddressValidationSchema = {
    subscriptionInputValue: yup
        .string()
        .required(validationErrorMessages.requiredField)
        .max(subscriptionInputValidationValues.email.maxLength),
};

type PhoneNumberError = "INVALID_COUNTRY" | "NOT_A_NUMBER" | "TOO_LONG" | "TOO_SHORT";

const subscriptionPhoneNumberValidationSchema = {
    subscriptionInputValue: yup
        .string()
        .required(validationErrorMessages.requiredField)
        .max(subscriptionInputValidationValues.phoneNumber.maxLength)
        .test("is-valid-phone-number", (value, context) => {
            if (value === undefined) return false;

            try {
                const phoneNumber = parsePhoneNumberWithError(value);

                return phoneNumber !== undefined;
            } catch (error) {
                if (error instanceof ParseError) {
                    const message = error.message as PhoneNumberError;

                    if (message === "INVALID_COUNTRY")
                        return context.createError({
                            message:
                                "Invalid country code. Please use a valid country code. For example +44",
                        });

                    if (message === "NOT_A_NUMBER")
                        return context.createError({ message: "Not a number" });

                    if (message === "TOO_LONG") return context.createError({ message: "Too long" });

                    if (message === "TOO_SHORT")
                        return context.createError({ message: "Too short" });
                }

                return context.createError({ message: "Phone number could not be parsed" });
            }
        }),
};

export const subscriptionInputValidationSchema = yup.lazy(({ subscriptionMethodType }) => {
    return yup
        .object()
        .shape({
            name: yup
                .string()
                .required(validationErrorMessages.requiredField)
                .max(subscriptionInputValidationValues.name.maxLength),
        })
        .shape(
            subscriptionMethodType === "Email"
                ? subscriptionEmailAddressValidationSchema
                : subscriptionPhoneNumberValidationSchema
        );
});

export default subscriptionInputValidationSchema;
