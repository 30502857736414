import React from "react";
import { useParams } from "react-router-dom";

import useGetEventViaShortcode from "hooks/dataFetching/useGetEventViaShortcode";

import PageSection from "components/layout/PageSection";
import ErrorPage from "pages/error/ErrorPage";
import EventDisplay from "./EventDisplay";

export default function EventPage() {
    const { eventShortCode } = useParams<{ eventShortCode: string }>();

    const { isLoading, isError, data, error } = useGetEventViaShortcode(eventShortCode);

    if (isError && error !== null) {
        return (
            <ErrorPage
                errorCode={500}
                title="There was an error fetching the event."
                text="Please try again later."
            />
        );
    }

    return (
        <PageSection>
            <EventDisplay loading={isLoading} event={data} />
        </PageSection>
    );
}
