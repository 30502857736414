import React, { useState } from "react";

import { createStyles, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import InputAdornment from "@material-ui/core/InputAdornment";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import PageSection from "components/layout/PageSection";
import StyledTextField from "components/inputs/StyledTextField";
import StyledDateTimePicker from "components/inputs/datePickers/StyledDateTimePicker";
import StyledDatePicker from "components/inputs/datePickers/StyledDatePicker";
import LoadableButton from "components/buttons/LoadableButton";

import { eventSubscriberApiConsumer } from "apiConsumers";
import IPostEventResponse from "apiConsumers/consumers/eventSubscriber/models/response/IPostEventResponse";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { hasError } from "utils/formValidationUtils";
import newEventValidationSchema from "./newEventValidationSchema";
import newEventValidationValues from "./newEventValidationValues";
import NewEventFormData from "./NewEventFormData";
import convertNewEventFormData from "./convertNewEventFormData";

const useStyles = makeStyles((theme) => {
    const textFieldWidth = "35ch";

    return createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            "& .MuiTextField-root": {
                margin: theme.spacing(1, 0),
                width: textFieldWidth,
            },
        },
        startTimeContainer: {
            display: "flex",
            alignItems: "center",
        },
        allDaySwitch: {
            marginLeft: theme.spacing(2),
        },
        subTitle: {
            marginTop: theme.spacing(4),
        },
        buttonContainer: {
            marginTop: theme.spacing(2),
            display: "flex",
            justifyContent: "flex-end",
            width: textFieldWidth,
        },
    });
});

interface IProps {
    onSuccess: (response: IPostEventResponse) => void;
}

export default function NewEventFormPage(props: IProps) {
    const classes = useStyles();
    const { onSuccess } = props;

    const { control, handleSubmit, errors } = useForm<NewEventFormData>({
        resolver: yupResolver(newEventValidationSchema),
    });

    const [creatingEvent, setCreatingEvent] = useState<boolean>(false);
    const [erroredPostingResults, setErroredPostingResults] = useState<boolean>(false);

    const onSubmit = handleSubmit((data: NewEventFormData) => {
        setCreatingEvent(true);

        const postEventData = convertNewEventFormData(data);

        eventSubscriberApiConsumer
            .postEvent(postEventData)
            .then(onSuccess)
            .catch((error) => {
                setErroredPostingResults(true);
                console.log(error);

                setCreatingEvent(false);
            });
    });

    const [allDay, setAllDay] = useState<boolean>(false);
    const startTimePickerProps = {
        label: "Start time",
        disablePast: true,
        required: true,
        error: hasError(errors.startTime?.message),
        helperText: errors.startTime?.message,
        disabled: creatingEvent,
    };

    return (
        <>
            <PageSection>
                <Typography variant="h2">Create new event</Typography>

                <form className={classes.root} onSubmit={onSubmit}>
                    <Typography className={classes.subTitle} variant="h6" gutterBottom>
                        Event details
                    </Typography>

                    <Controller
                        name="title"
                        control={control}
                        defaultValue=""
                        render={({ ref, value, onChange }) => (
                            <StyledTextField
                                label="Title"
                                inputRef={ref}
                                value={value}
                                onChange={onChange}
                                error={hasError(errors.title?.message)}
                                helperText={errors.title?.message}
                                disabled={creatingEvent}
                                required
                                inputProps={{ maxLength: newEventValidationValues.title.maxLength }}
                            />
                        )}
                    />

                    <Controller
                        name="description"
                        control={control}
                        defaultValue=""
                        render={({ ref, value, onChange }) => (
                            <StyledTextField
                                label="Description"
                                multiline
                                rowsMax={16}
                                inputRef={ref}
                                value={value}
                                onChange={onChange}
                                error={hasError(errors.description?.message)}
                                helperText={errors.description?.message}
                                disabled={creatingEvent}
                                required
                                inputProps={{
                                    maxLength: newEventValidationValues.description.maxLength,
                                }}
                            />
                        )}
                    />

                    <div className={classes.startTimeContainer}>
                        <Controller
                            name="startTime"
                            control={control}
                            defaultValue={null}
                            render={({ onChange, onBlur, value }) =>
                                allDay ? (
                                    <StyledDatePicker
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        {...startTimePickerProps}
                                    />
                                ) : (
                                    <StyledDateTimePicker
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        value={value}
                                        {...startTimePickerProps}
                                    />
                                )
                            }
                        />

                        <FormControlLabel
                            className={classes.allDaySwitch}
                            control={
                                <Switch checked={allDay} onChange={() => setAllDay(!allDay)} />
                            }
                            label="All day"
                            disabled={creatingEvent}
                        />
                    </div>

                    <Controller
                        name="latitude"
                        control={control}
                        defaultValue=""
                        render={({ ref, value, onChange }) => (
                            <StyledTextField
                                label="Latitude"
                                type="number"
                                inputRef={ref}
                                value={value}
                                onChange={onChange}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">°</InputAdornment>,
                                }}
                                error={hasError(errors.latitude?.message)}
                                helperText={errors.latitude?.message}
                                disabled={creatingEvent}
                                required
                                inputProps={{
                                    step: newEventValidationValues.coordinate.step,
                                    min: newEventValidationValues.coordinate.latitude.min,
                                    max: newEventValidationValues.coordinate.latitude.max,
                                }}
                            />
                        )}
                    />

                    <Controller
                        name="longitude"
                        control={control}
                        defaultValue=""
                        render={({ ref, value, onChange }) => (
                            <StyledTextField
                                label="Longitude"
                                type="number"
                                inputRef={ref}
                                value={value}
                                onChange={onChange}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">°</InputAdornment>,
                                }}
                                error={hasError(errors.longitude?.message)}
                                helperText={errors.longitude?.message}
                                disabled={creatingEvent}
                                required
                                inputProps={{
                                    step: newEventValidationValues.coordinate.step,
                                    min: newEventValidationValues.coordinate.longitude.min,
                                    max: newEventValidationValues.coordinate.longitude.max,
                                }}
                            />
                        )}
                    />

                    <Typography className={classes.subTitle} variant="h6" gutterBottom>
                        Organiser details
                    </Typography>

                    <Controller
                        name="organiserName"
                        control={control}
                        defaultValue=""
                        render={({ ref, value, onChange }) => (
                            <StyledTextField
                                label="Name"
                                inputRef={ref}
                                value={value}
                                onChange={onChange}
                                error={hasError(errors.organiserName?.message)}
                                helperText={errors.organiserName?.message}
                                disabled={creatingEvent}
                                required
                                inputProps={{
                                    maxLength: newEventValidationValues.organiserName.maxLength,
                                }}
                            />
                        )}
                    />

                    <Controller
                        name="subscriptionPhoneNumber"
                        control={control}
                        defaultValue=""
                        render={({ ref, value, onChange }) => (
                            <StyledTextField
                                label="Phone number"
                                inputRef={ref}
                                value={value}
                                onChange={onChange}
                                error={hasError(errors.subscriptionPhoneNumber?.message)}
                                helperText={errors.subscriptionPhoneNumber?.message}
                                disabled={creatingEvent}
                                required
                                inputProps={{
                                    maxLength:
                                        newEventValidationValues.subscriptionPhoneNumber.maxLength,
                                }}
                            />
                        )}
                    />

                    <div className={classes.buttonContainer}>
                        <LoadableButton loading={creatingEvent} color="secondary" type="submit">
                            Create
                        </LoadableButton>
                    </div>
                </form>
            </PageSection>

            <Snackbar
                open={erroredPostingResults}
                autoHideDuration={10000}
                onClose={() => setErroredPostingResults(false)}
            >
                <Alert
                    elevation={6}
                    onClose={() => setErroredPostingResults(false)}
                    severity="error"
                >
                    There has been an error creating the event. Please try again later.
                </Alert>
            </Snackbar>
        </>
    );
}
