import { HTMLAttributes, useState } from "react";

import StyledTextField from "./inputs/StyledTextField";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";

import copy from "clipboard-copy";

interface IProps extends HTMLAttributes<HTMLElement> {
    text: string;
    className?: string;
}

export default function CopyToClipboard(props: IProps) {
    const { text, className } = props;

    const [copied, setCopied] = useState(false);

    const handleCopyClick = async () => {
        copy(text).then(() => {
            setCopied(true);
        });
    };

    return (
        <>
            <StyledTextField
                className={className}
                value={text}
                disabled
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Tooltip title="Copy to clipboard" placement="top">
                                <IconButton onClick={handleCopyClick} edge="end">
                                    <FileCopyIcon />
                                </IconButton>
                            </Tooltip>
                        </InputAdornment>
                    ),
                }}
            />

            <Snackbar open={copied} autoHideDuration={5000} onClose={() => setCopied(false)}>
                <SnackbarContent message="Copied!" />
            </Snackbar>
        </>
    );
}
