import React from "react";

import { IEventBaseLocation } from "apiConsumers/consumers/eventSubscriber/models/IEvent";

import Typography from "@material-ui/core/Typography";
import StyledSkeleton from "components/StyledSkeleton";

interface IGeocoordLocation extends IEventBaseLocation {
    latitude: number;
    longitude: number;
}

interface IProps {
    location?: IEventBaseLocation;
    loading?: boolean;
}

export default function LocationDisplay({ location, loading = false }: IProps) {
    if (loading || location === undefined) return <LocationPlaceholder />;

    if (location.type === "GeoCoord") {
        const geoCoord = location as IGeocoordLocation;

        return (
            <>
                <Typography>Latitude: {geoCoord.latitude}</Typography>
                <Typography>Longitude: {geoCoord.longitude}</Typography>
            </>
        );
    }

    return <Typography color="error">Unable to display location</Typography>;
}

const LocationPlaceholder = () => {
    return (
        <Typography>
            <StyledSkeleton width={100} />
        </Typography>
    );
};
